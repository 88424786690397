'use client';
import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Link,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { FaChartLine, FaTable } from 'react-icons/fa';
import {
  Subject,
  combineLatest,
  debounceTime,
  exhaustMap,
  filter,
  first,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { fullUsersCache } from '../../lib/caches';
import { getUserGamertag, xboxClient } from '../../lib/clients';
import { useObservable } from '../../lib/hooks/use-observable';
import { requestPolicy } from '@halo-data/utilities';
import { ResultsList } from './results-list';
import SearchInput from './search-input';

export default function PlayerSearch({
  defaultValue,
}: {
  defaultValue?: string;
}) {
  const pathname = usePathname();

  const textFieldValue$ = useMemo(() => new Subject<string>(), []);
  const search$: Subject<string> = useMemo(() => new Subject<string>(), []);
  const results$ = useMemo(() => {
    const trimmedSearch$ = search$.pipe(map((search) => search.trim()));
    return combineLatest([
      trimmedSearch$.pipe(map((search) => search.toLowerCase())),
      trimmedSearch$.pipe(
        filter((search) => !!search),
        debounceTime(200),
        switchMap((search) =>
          requestPolicy
            .execute((ctx) =>
              xboxClient.searchUsers(search, { signal: ctx.signal })
            )
            .catch(() => [])
        ),
        startWith(
          [] as {
            xuid: string;
            gamertag: string;
            displayPicRaw: string | undefined;
          }[]
        )
      ),
      trimmedSearch$.pipe(
        first((search) => !!search),
        exhaustMap(() => getUserGamertag()),
        switchMap((userGamertag) =>
          fullUsersCache.get(userGamertag).catch(() => undefined)
        ),
        startWith(undefined)
      ),
      trimmedSearch$.pipe(
        filter((search) => !!search),
        switchMap((search) =>
          fullUsersCache.get(search).catch(() => undefined)
        ),
        startWith(undefined)
      ),
    ]).pipe(
      map(
        ([trimmedLowercaseKeyword, searchResults, currentUser, searchUser]) => {
          if (!trimmedLowercaseKeyword) {
            return [];
          }

          const results: {
            xuid: string;
            gamertag: string;
            displayPicRaw: string | undefined;
          }[] = [];

          if (
            currentUser?.gamertag
              .toLowerCase()
              .startsWith(trimmedLowercaseKeyword)
          ) {
            // Current user's gamertag is a partial
            // search match, add it as the second entry in the list
            results.push({
              xuid: currentUser.xuid,
              gamertag: currentUser.gamertag,
              displayPicRaw: currentUser.gamerpic.small,
            });
          }

          if (
            searchUser?.gamertag
              .toLowerCase()
              .startsWith(trimmedLowercaseKeyword)
          ) {
            // Search keyword is a user, add it as the first entry in the list
            results.push({
              xuid: searchUser.xuid,
              gamertag: searchUser.gamertag,
              displayPicRaw: searchUser.gamerpic.small,
            });
          }

          // For some reason, not all gamertags appear in suggestions
          return (
            results
              .concat(searchResults)
              // I also don't understand how we could get a null user object here,
              // but the logs don't lie.
              .filter((r) => r?.gamertag)
              .distinct((r1, r2) => r1.gamertag === r2.gamertag)
          );
        }
      )
    );
  }, [search$]);

  useEffect(() => {
    textFieldValue$.next(defaultValue ?? '');
  }, [defaultValue, textFieldValue$]);
  const value = useObservable(textFieldValue$, '');

  return (
    <>
      <Flex>
        <Box>
          {defaultValue && value === defaultValue && (
            <ButtonGroup spacing={1}>
              {pathname !== '/matches' && (
                <Link as={NextLink} href={'/matches?gamertag=' + defaultValue}>
                  <IconButton
                    aria-label="Matches"
                    title="Matches"
                    icon={<Icon as={FaTable} />}
                  />
                </Link>
              )}
              {!pathname.startsWith('/players') && (
                <Link as={NextLink} href={'/players/' + defaultValue}>
                  <IconButton
                    aria-label="Profile"
                    title="Profile"
                    icon={<Icon as={FaChartLine} />}
                  />
                </Link>
              )}
            </ButtonGroup>
          )}
        </Box>
        <Box flexGrow={1}>
          <SearchInput
            value$={textFieldValue$}
            setInputValue={(value) => {
              textFieldValue$.next(value);
              search$.next(value);
            }}
          />
        </Box>
      </Flex>
      <Flex position={'relative'}>
        <ResultsList
          results$={results$}
          onResultClick={(gamertag) => {
            textFieldValue$.next(gamertag);
            search$.next('');
          }}
        />
      </Flex>
    </>
  );
}
