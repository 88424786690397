'use client';
import type { UserInfo, XboxTicket } from 'halo-infinite-api';
import { RelyingParty } from 'halo-infinite-api';
import { useReadLocalStorage } from '../hooks/local-storage';
import { useEffect, useState } from 'react';
import { fullUsersCache } from '../caches';
import { initialize } from '../msal-instances/client';
import { useToast } from '@chakra-ui/react';

export function useCurrentUserGamertag() {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    initialize().then(() => setInitialized(true));
  }, [setInitialized]);

  const xboxXstsTicket = useReadLocalStorage<XboxTicket>(
    'xbox.xstsTicket.' + RelyingParty.Xbox
  );
  if (initialized && xboxXstsTicket) {
    return xboxXstsTicket.DisplayClaims.xui[0].gtg as string;
  }
  return null;
}

export function useCurrentUser() {
  const toast = useToast();
  const [user, setUser] = useState<UserInfo>();

  const gamertag = useCurrentUserGamertag();
  useEffect(() => {
    if (gamertag) {
      fullUsersCache
        .get(gamertag)
        .then((userInfo) => setUser(userInfo))
        .catch((e) => {
          toast({
            id: 'user-fetch-error',
            title: 'Error fetching user',
            position: 'top',
            description: e.message,
            status: 'error',
            isClosable: true,
          });
        });
    }
  }, [toast, gamertag]);

  return user;
}
