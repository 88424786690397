import { ResolvablePromise } from '@halo-data/utilities';
import { IDependencyInitializerDetails } from '@microsoft/applicationinsights-dependencies-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BatchInterceptor } from '@mswjs/interceptors';
import { FetchInterceptor } from '@mswjs/interceptors/fetch';
import { XMLHttpRequestInterceptor } from '@mswjs/interceptors/XMLHttpRequest';
import ExpiryMap from 'expiry-map';
import ExpirySet from 'expiry-set';
import { Duration } from 'luxon';

// Persist HTTP bodies for 10 minutes or until application insights
// logs them
const data = new ExpiryMap<
  string,
  ResolvablePromise<{
    request: { headers: string; body: string };
    response: { headers: string; body: string };
  }>
>(10 * 60 * 1000);
// Track which requests have been handled for 10 minutes, then forget
const handledRequests = new ExpirySet<string>(10 * 60 * 1000);

const interceptor = new BatchInterceptor({
  name: 'dependency-body-processing-interceptor',
  interceptors: [
    new FetchInterceptor(),
    new XMLHttpRequestInterceptor(),
  ] as const,
});

interceptor.apply();
interceptor.on('response', async ({ request, response }) => {
  const appInsightsId = request.headers.get('request-id');
  if (appInsightsId) {
    let dataPromise = data.get(appInsightsId);

    if (!dataPromise) {
      dataPromise = new ResolvablePromise();
      data.set(appInsightsId, dataPromise);
    }

    if (dataPromise.isCompleted) {
      return;
    }

    const requestHeaders: string[] = [];
    request.headers.forEach((value, key) => {
      requestHeaders.push(`${key}: ${value}`);
    });

    const responseHeaders: string[] = [];
    response.headers.forEach((value, key) => {
      responseHeaders.push(`${key}: ${value}`);
    });

    dataPromise.resolve({
      request: {
        headers: requestHeaders.join('\n'),
        body: await request.clone().text(),
      },
      response: {
        headers: responseHeaders.join('\n'),
        body: await response.clone().text(),
      },
    });
  }
});

export const handleDependencyTelemetry = (
  envelope: IDependencyInitializerDetails,
  telemetryClient: ApplicationInsights
) => {
  // Ignore successful telemetry and matches/skill 404s
  if (envelope.item.success) {
    return false;
  } else {
    if (+envelope.item.responseCode === 404 && envelope.item.target) {
      try {
        const targetUrl = new URL(envelope.item.target);
        if (
          /\/matches\/[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}\/skill$/.test(
            targetUrl.pathname
          )
        ) {
          // 404s for skill data are expected
          return false;
        }
      } catch (e) {
        // Ignore invalid URLs
      }
    }
  }

  let shouldSubmitTelemetry = true;
  if (['Http', 'Fetch'].includes(envelope.item.type as string)) {
    const requestId = envelope.item.id;

    if (!handledRequests.has(requestId)) {
      let dataPromise = data.get(requestId);
      if (!dataPromise) {
        dataPromise = new ResolvablePromise();
        data.set(requestId, dataPromise);
      }

      dataPromise.then(({ request, response }) => {
        const existingTelemetry = envelope.item;
        telemetryClient.trackDependencyData({
          ...existingTelemetry,
          name: existingTelemetry.name ?? envelope.item.name ?? '',
          startTime: envelope.item.startTime
            ? new Date(envelope.item.startTime)
            : undefined,
          type: existingTelemetry.type ?? envelope.item.type,
          duration:
            typeof existingTelemetry.duration === 'string'
              ? Duration.fromISOTime(
                  existingTelemetry.duration as string
                ).toMillis()
              : (existingTelemetry.duration as number),
          properties: {
            ...existingTelemetry.properties,
            ResponseBody: response.body,
            RequestBody: request.body,
            ResponseHeaders: response.headers,
            RequestHeaders: request.headers,
          },
        });
      });
      // Don't submit telemetry until response has been read
      shouldSubmitTelemetry = false;

      // Request has been handled, ignore future telemetry
      handledRequests.add(requestId);
    }
  }

  return shouldSubmitTelemetry;
};
